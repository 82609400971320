/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import {
  closeLocationModalUpdate,
  updateLocation,
} from '../../redux/features/location/locationSlice';

export default function UpdateLocation ({ modalShow, handleClose }) {
  const dispatch = useDispatch();
  const { showLocationModalUpdate, location } = useSelector(state => state.location);
  const [loc, setLoc] = useState(location);
  const [management, setManagement] = useState(location?.management ? location?.management : null);

  useEffect(() => {
    setLoc(location);
    setManagement(location?.management);
  }, [location]);

  // useEffect(() => {
  //   // eslint-disable-next-line object-shorthand
  //   setLoc({ ...location, management: management });
  // }, [management]);

  function handleLocationUpdate (e) {
    e.preventDefault();
    //set the nwe management data to the location state
    const info = {
      id: location.id,
      data: loc,
    };
    dispatch(updateLocation(info));
    dispatch(closeLocationModalUpdate());
    handleClose();
  }
  const formateDate = date => {
    const inputDate = new Date(date);

    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');
    // const hours = String(inputDate.getHours()).padStart(2, '0');
    // const minutes = String(inputDate.getMinutes()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  return (
    <Modal show={modalShow} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Edit Site</Modal.Title>
        <Button variant="primary" onClick={handleClose}>
          X
        </Button>
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        <div>
          <form onSubmit={handleLocationUpdate}>
            <div className="card-header" id="site-edit">
              <div className="card-title row">
                <div className="col-12">
                  <div className="row">
                    <div className="location_error_msg">
                      <span className="font-danger"> </span>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <label className="font-weight-normal" htmlFor="site_name">
                      Location/Branch{' '}
                        <label className="font-danger m-0" htmlFor="site_name">
                          *
                        </label>
                      </label>
                      <input
                        name="site_name"
                        type="text"
                        className="form-control mb-2"
                        required
                        placeholder="Location/Branch"
                        id="site_name"
                        value={loc.site}
                        onChange={e => setLoc({ ...loc, site: e.target.value })}
                        disabled={loc?.from_ms}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                    <label className="font-weight-normal" htmlFor="location_code">
                      Location Code{' '}
                      <label className="m-0 font-danger" htmlFor="location_code">
                        *
                      </label>
                    </label>
                    <input
                      name="location_code"
                      type="text"
                      className="form-control mb-2"
                      placeholder="Location Code"
                      id="location_code"
                      required
                      disabled={loc?.from_ms}
                      value={loc?.location_code}
                      onChange={e => setLoc({ ...loc, location_code: e.target.value })}
                    />
                  </div>
                    <div className="col-lg-6 col-sm-12">
                      <label className="font-weight-normal" htmlFor="site_street">
                        Street
                      </label>
                      <input
                        name="site_street"
                        type="text"
                        className="form-control mb-2"
                        placeholder="Street (Optional)"
                        value={loc.street}
                        onChange={e => setLoc({ ...loc, street: e.target.value })}
                      />
                    </div>
                    <div className="col-lg-3 col-md-8 col-sm-12">
                      <label className="font-weight-normal" htmlFor="site_district">
                        District
                      </label>
                      <input
                        name="site_district"
                        type="text"
                        className="form-control mb-2"
                        placeholder="District (Optional)"
                        value={loc.district}
                        onChange={e => setLoc({ ...loc, district: e.target.value })}
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <label className="font-weight-normal" htmlFor="site_city">
                        City
                      </label>
                      <input
                        name="site_city"
                        type="text"
                        className="form-control mb-2"
                        placeholder="City (Optional)"
                        value={loc.city}
                        onChange={e => setLoc({ ...loc, city: e.target.value })}
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <label className="font-weight-normal" htmlFor="site_state">
                        State
                      </label>
                      <input
                        name="site_state"
                        type="text"
                        className="form-control mb-2"
                        placeholder="State (Optional)"
                        value={loc.state}
                        onChange={e => setLoc({ ...loc, state: e.target.value })}
                      />
                    </div>
                    <div className="col-lg- col-md-4 col-sm-8">
                      <label className="font-weight-normal" htmlFor="site_postcode">
                        Postal Code
                      </label>
                      <input
                        name="site_postcode"
                        type="text"
                        className="form-control mb-2"
                        placeholder="Postal Code (Optional)"
                        value={loc.postal_code}
                        onChange={e => setLoc({ ...loc, postal_code: e.target.value })}
                      />
                    </div>
                    {/* {loc.management && ( */}
                    {/* <section>
                      <div className="ms-2">
                        <h4 className="">
                          <b>Property Information</b>
                        </h4>
                      </div>
                      <div className="">
                        <div className="col-12">
                          <div className="row">
                            {/* <div className="col-lg-6 col-md-6 col-sm-6">
                                <label className="font-weight-normal" htmlFor="tenant_name_new">
                                  Tenant/Owner Name{' '}
                                </label>
                                <input
                                  name="tenant_name"
                                  type="text"
                                  className="form-control mb-2"
                                  placeholder="Tenant Name (Optional)"
                                  id="tenant_name_new"
                                  value={management?.tenant}
                                  onChange={e =>
                                    setManagement({ ...management, tenant: e.target.value })
                                  }
                                />
                              </div> */}
                    {/* <div className="col-6">
                              <label className="font-weight-normal" htmlFor="owner_name_new">
                                Owner Name{' '}
                              </label>
                              <input
                                name="owner_name"
                                type="text"
                                className="form-control mb-2"
                                placeholder="Owner Name (Optional)"
                                id="owner_name_new"
                                value={management?.owner}
                                onChange={e =>
                                  setManagement({
                                    ...management,
                                    owner: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="col-6">
                              <label className="font-weight-normal" htmlFor="tenant_name_new">
                                Tenant Name{' '}
                              </label>
                              <input
                                name="tenant_name"
                                type="text"
                                className="form-control mb-2"
                                placeholder="Tenant Name (Optional)"
                                id="tenant_name_new"
                                value={management?.tenant}
                                onChange={e =>
                                  setManagement({
                                    ...management,
                                    tenant: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 ">
                              <label className="font-weight-normal">Rent Category</label>
                              <select
                                className=" mb-2 select-meter"
                                name="category"
                                id="site_category"
                                onChange={e =>
                                  setManagement({
                                    ...management,
                                    category: e.target.value,
                                  })
                                }
                              >
                                <option value="">Select a Category</option>

                                {['Rented', 'Rental'].map(val =>
                                  val === management?.category ? (
                                    <option selected value={val}>
                                      {val}
                                    </option>
                                  ) : (
                                    <option value={val}>{val}</option>
                                  ),
                                )}
                              </select>
                            </div>
                            <div className=" col-lg-6 col-md-6 col-sm-6">
                              <label className="font-weight-normal">Start Date</label>
                              <input
                                type="date"
                                className="form-control mb-2"
                                name="service_date"
                                id="rent_start_date"
                                placeholder="Start Date"
                                value={formateDate(management?.start_rent_date?.split('T')[0])}
                                onChange={e =>
                                  setManagement({
                                    ...management,
                                    start_rent_date: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className=" col-lg-6 col-md-6 col-sm-6">
                              <label className="font-weight-normal">End Rent Date</label>
                              <input
                                type="date"
                                className="form-control mb-2"
                                name="service_date"
                                id="rent_start_date"
                                placeholder="Start Date"
                                value={formateDate(management?.end_rent_date?.split('T')[0])}
                                onChange={e =>
                                  setManagement({
                                    ...management,
                                    end_rent_date: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className=" col-lg-12">
                              <label className="font-weight-normal">Description</label>
                              <textarea
                                name="description"
                                value={management?.description}
                                onChange={e =>
                                  setManagement({
                                    ...management,
                                    description: e.target.value,
                                  })
                                }
                                className="form-control mb-2"
                                placeholder="Description"
                                id="description"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>  */}
                    {/* )} */}
                    <div className="col-lg-12 col-sm-12 m-0">
                      <span className="font-danger" id="location_name_error">
                        {' '}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="float-right row mt-3">
                <div className="row float-right">
                  <button title="Update Location" className="btn primary-btn ml-2 " type="submit">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <button title="Update Site" className="btn primary-btn ml-2 " type="submit" onClick={handleClose}>
                    Save
                  </button>
      </Modal.Footer> */}
    </Modal>
  );
}
