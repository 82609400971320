import React from "react";
import {
  PolarAngleAxis,
  RadialBar,
  RadialBarChart,
  ResponsiveContainer,
} from "recharts";
// import { RadialBarChart, PolarAngleAxis, RadialBar } from 'recharts';

/* eslint-disable no-nested-ternary */

export default function ProgressChart({ color, percentage }) {
  // Single data point
  const data = [{ name: "A", value: Number.isNaN(percentage) || !percentage ? 0 : (Number.isInteger(percentage) ? percentage : percentage.toFixed(1)), fill: color }];
  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadialBarChart
        width={231} // Adjusted size for better visibility
        height={231}
        data={data}
        innerRadius="110%" // Increased inner radius to create more space for the text
        outerRadius="140%" // Adjusted outer radius
        // barSize={12} // Adjusted bar size
        startAngle={90}
        endAngle={-270}
      >
        <PolarAngleAxis
          type="number"
          domain={[0, 100]} // Adjusted domain to fit your data
          angleAxisId={0}
          tick={false}
        />
        <RadialBar
          background
          dataKey="value"
          cornerRadius={3} // Adjusted corner radius
        />
        <text
          x={28} // Centering text horizontally
          y={28} // Centering text vertically
          textAnchor="middle"
          dominantBaseline="middle"
          className="progress-label"
          fontWeight={400}
          fontSize="14px" // Adjust font size for better visibility
          // fill="#669ECDEB" // Adjust text color if needed
          fill="#4A4C50"
        >
          {`${data[0].value}%`}
        </text>
        {/* <text
        x={116} // Centering text horizontally
        y={200} // Centering text vertically
        textAnchor="middle"
        dominantBaseline="middle"
        className="progress-label"
        fontWeight={600}
        fontSize="18px" // Adjust font size for better visibility
        fill="#727478" // Adjust text color if needed
      >
        {label}
      </text> */}
      </RadialBarChart>
    </ResponsiveContainer>
  );
}
