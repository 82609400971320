/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from '../../pages/assetsPage/assestsPage.module.css';

const PropertyManagementHeader = ({ myLocation, setSearchValue }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState(myLocation || {});
  const { showProp } = useSelector(state => state.property);

  return (
    <div className={classes.assetsTop}>
      <h4 className={classes.assetsTitle}>Locations</h4>
      {!showProp && (
        <div className={classes.assetActions}>
          <input onChange={e => setSearchValue(e.target.value)} type="text" placeholder="Search" />
        </div>
      )}
    </div>
  );
};

export default PropertyManagementHeader;
