/* eslint-disable arrow-body-style */

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getAssetListSearch, setAsset } from '../../redux/features/asset/assetSlice';
import classes from '../assetsPage/assestsPage.module.css';
import Pagination from '../../components/pagination/pagination';
import Loader from '../../components/loader/loader';
import MeterHeader from './Componets/meterHeader';
import MeterTable from './Componets/meterTable';
import AddMeterModal from './Componets/AddMeterModal';
import { getTeamsByAuthentication } from '../../redux/features/teams/teamSlice';
import { getLocationsByAuthentication } from '../../redux/features/location/locationSlice';
// import EditMeterModal from './Componets/EditMeterModal';
import {
  closeMeterModalCreate,
  closeMeterModalUpdate,
  getMeterById,
  getMetersByPagination,
  openMeterModalUpdate,
  resetMeter,
  setTab,
  reset,
  setCurrentPage,
  setRowPerPage,
} from '../../redux/features/meter/meterSlice';
import { getMeterCategoryByAuthentication } from '../../redux/features/meterCategory/meterCategorySlice';
import { getWorkOrderCategoryByAuthentication } from '../../redux/features/workOrderCategory/workOrderCategorySlice';
import {
  closeTrigger,
  reset as resetStatus,
} from '../../redux/features/workOrderTrigger/workOrderTriggerSlice';
import { selectActions } from '../../redux/features/selectOptions/selectSlice';

/* eslint-disable no-nested-ternary */


export default function MeterPage () {
  const dispatch = useDispatch();
  const {user} = useSelector(state => state.user)

  useEffect(() => {
    dispatch(getMeterCategoryByAuthentication());
    dispatch(getTeamsByAuthentication());
    dispatch(getLocationsByAuthentication());
    const fmValue = user?.profile?.department_name === "Facilities Management"
    dispatch(getAssetListSearch({assetSearch :"" , bool: fmValue}));
    dispatch(getWorkOrderCategoryByAuthentication());
  }, []);

  const meterDataCount = useSelector(state => state.meter?.meters?.count);
  const [iSShowFilter, setISShowFilter] = useState(false);
  const [iSShowCheckboxs, setISShowCheckboxs] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  // const [currentPage, setCurrentPage] = useState(1);
  // const [rowPerPage, setRowPerPage] = useState(10);

  const {
    SearchVal,
    meters,
    meterModalCreate,
    // meterModalUpdate,
    isMeterCreateError,
    isMeterUpdateError,
    isMeterCreateSuccess,
    isMeterDeleteError,
    isMeterDeleteSuccess,
    isMeterUpdateSuccess,
    currentPage,
    rowPerPage 
  } = useSelector(state => state.meter);
  const {
    isTriggerCreateError,
    isTriggerUpdateError,
    isTriggerCreateSuccess,
    isTriggerDeleteSuccess,
    isTriggerUpdateSuccess,
  } = useSelector(state => state.trigger);

  // useEffect(() => {
  //   if (SearchVal !== '') {
  //     setCurrentPage(1);
  //   }
  // }, [SearchVal]);

  useEffect(() => {
    const getDataRows = async () => {
      await dispatch(getMetersByPagination({ rowPerPage, currentPage, SearchVal }));
      setIsLoading(false);
    };

    getDataRows();
  }, [rowPerPage, currentPage, SearchVal]);

  const getSelectedRowwValues = async (selectedRow, cell) => {
    if (cell.column.id !== 'delete-table-column') {
      dispatch(resetMeter());
      dispatch(setAsset(null));
      dispatch(selectActions.reset());
      await dispatch(getMeterById(selectedRow.id));
      dispatch(openMeterModalUpdate());
      dispatch(setTab('service-tab'));
    }
  };

  function displayToast () {
    if (isMeterCreateError) {
      toast.error('Something went wrong Creating Meter', {
        toastId: 'CreatedMeterError',
      });
      dispatch(reset());
    } else if (isMeterUpdateError) {
      toast.error(' Something went wrong Updating Meter', {
        toastId: 'UpdatingMeterError',
      });
      dispatch(reset());
    } else if (isMeterDeleteError) {
      toast.error(' Something went wrong Deleting Meter', {
        toastId: 'DeletingMeterError',
      });
      dispatch(reset());
    } else if (isMeterCreateSuccess) {
      toast.success(' Meter Created successfully', {
        toastId: 'Meter Created Success',
      });
      dispatch(closeMeterModalCreate());
      dispatch(resetMeter());
      dispatch(reset());
    } else if (isMeterDeleteSuccess) {
      toast.success(' Meter Deleted successfully', {
        toastId: 'DeletedMeterError',
      });
      dispatch(reset());
      dispatch(resetMeter());
      dispatch(closeMeterModalUpdate());
    } else if (isMeterUpdateSuccess) {
      toast.success(' Meter Updated successfully', {
        toastId: 'UpdatedMeterError',
      });
      dispatch(reset());
    } else if (isTriggerCreateError) {
      toast.error('Something went wrong Creating Trigger', {
        toastId: 'CreatedTriggerError',
      });
      dispatch(resetStatus());
    } else if (isTriggerUpdateError) {
      toast.error('Something went wrong Updating Trigger', {
        toastId: 'UpdatedTriggerError',
      });
      dispatch(resetStatus());
    } else if (isTriggerCreateSuccess) {
      toast.success(' Trigger Created successfully', {
        toastId: 'CreatedTrigeerError',
      });
      dispatch(closeTrigger());
      dispatch(resetStatus());
    } else if (isTriggerUpdateSuccess) {
      toast.success(' Trigger Updated successfully', {
        toastId: 'UpdatedTrigeerError',
      });
      dispatch(closeTrigger());
      dispatch(resetStatus());
    } else if (isTriggerDeleteSuccess) {
      toast.success(' Trigger Deleted successfully', {
        toastId: 'DeletedTrigeerError',
      });
      dispatch(resetStatus());
    }
  }
  const modifiedData = meters?.results?.map(m => {
    return {
      id: m?.id,
      name: m?.title,
      assignedPersons: m?.assigned_to.length > 0 ? m?.assigned_to : '—',
      readingDate: m?.reading_date || '—',
      created_at: m?.created_at || '—',
      frequency: (!m?.interval || !m?.frequency) ? '—' : `${m?.interval} ${m?.frequency}` ,
      unit: m?.measurement_unit?.name || '—',
      location: m?.location?.site || '—',
      asset: m?.asset?.name || '—',
      status: m?.status || '—',
      priority: m?.priority,
      category: m?.category?.name || '—',
      createdBy: m?.abstract?.created_by?.role,
      ticketNumber: m?.meter_number
    };
  });

  return (
    <>
      {displayToast()}
      <div className={classes.assets}>
        <MeterHeader setISShowFilter={setISShowFilter} iSShowFilter={iSShowFilter} />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <MeterTable
              iSShowFilter={iSShowFilter}
              setISShowFilter={setISShowFilter}
              iSShowCheckboxs={iSShowCheckboxs}
              setISShowCheckboxs={setISShowCheckboxs}
              getSelectedRowwValues={getSelectedRowwValues}
              meterData={modifiedData || []}
              isLoading={isLoading}
            />
            <Pagination
              totalRows={meterDataCount}
              rowPerPage={rowPerPage}
              pageChangeHandler={setCurrentPage}
              changeRowsPerPage={setRowPerPage}
              currentPage={currentPage}
            />
          </>
        )}
      </div>
      {meterModalCreate && (
        <AddMeterModal
          openModalCreate={meterModalCreate}
          closeModalCreate={() => dispatch(closeMeterModalCreate())}
        />
      )}
      {/* {meterModalUpdate && (
        <EditMeterModal
          openModalUpdate={meterModalUpdate}
          closeModalUpdate={() => dispatch(closeMeterModalUpdate())}
        />
      )} */}
    </>
  );
}
