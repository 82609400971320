import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { endOfWeek, startOfWeek } from 'date-fns';
import moment from 'moment';
import eventService from './dashboardService';
import meterService from '../meter/meterService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // Assets
  events: [],
  event: {},

  taskPlannerBoardDataOrigin: [],
  maintenancePlannerBoardDataOrigin: [],

  taskPlannerBoardData :[],
  maintenancePlannerBoardData :[],

  maintenancePlannerChartData: [],
  maintenancePlannerChartCount: 0,

  // modals
  eventModalDelete: false,
  eventModalUpdate: false,
  eventModalCreate: false,

  // Event fields
  eventId: null,
  ticketType: "",
  title: "",
  description: "",
  priority: "",
  category: "",
  due_finish: "",
  start_date: "",
  completed_date: "",
  assigned_to: [],
  asset: "",
  location: "",
  time: "",
  status: "",
  fixedDate: "",
  rquestor: "",


  calendarView: "week",
  selectedDateOnCalendar: new Date(),

  filterFeatures : [
    { selected: true, label: 'Work order', backgroundColor: "#E3871E" },
    // { selected: true, label: 'Event', backgroundColor: "#20BB7D" },
    // { selected: true, label: 'Meter', backgroundColor: "#337DBC" },
  ],


  // filter calendar data
  calendarSite: null,
  calendarSublocation: null,
  calendarStaff: null,
  calendarFilterAdvanceStatus: [
    {label : "Open", selected: true},
    {label : "In-Progress", selected: true},
    {label : "On-Hold", selected: true},
    {label : "Completed", selected: true}
  ],

  // filter tasks board data
  servicesType: null,
  taskFilterStartDate: moment.utc(new Date()).startOf("isoWeek").format("YYYY-MM-DD"),
  taskFilterEndDate: moment.utc(new Date()).endOf("isoWeek").format("YYYY-MM-DD"),
  taskDateFilterState:[
    {
      startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
      endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
      key: 'selection',
    },
  ],
  tasksBoardSite: null,
  tasksBoardSublocation: null,
  tasksBoardStaff: null,


  // filter maintenance board data
  maintenanceChartSite: null,
  maintenanceChartSublocation: null,
  maintenanceChartStaff: null,
  maintenanceChartStatus: [
    {label : "Open", selected: true},
    {label : "In-Progress", selected: true},
    {label : "On-Hold", selected: true},
    {label : "Completed", selected: true}
  ],
  maintenanceChartFrequency: [
    {label : "Yearly", selected: true, value: "year"},
    {label : "Weekly", selected: true, value: "week"},
    {label : "Monthly", selected: true, value: "month"},
    {label : "Daily", selected: true, value: "day"}
  ],

  // filter maintenance board data
  maintenanceBoardSite: null,
  maintenanceBoardSublocation: null,
  maintenanceBoardStaff: null,
  maintenanceBoardStatus: [
    {label : "Open", selected: true},
    {label : "In-Progress", selected: true},
    {label : "On-Hold", selected: true},
    {label : "Completed", selected: true}
  ],
  maintenanceBoardFrequency: [
    {label : "Yearly", selected: true, value: "year"},
    {label : "Weekly", selected: true, value: "week"},
    {label : "Monthly", selected: true, value: "month"},
    {label : "Daily", selected: true, value: "day"}
  ],


  maintenanceFilterStartDate: moment.utc(new Date()).startOf("isoWeek").format("YYYY-MM-DD"),
  maintenanceFilterEndDate: moment.utc(new Date()).endOf("isoWeek").format("YYYY-MM-DD"),
  maintenanceDateFilterState:[
    {
      startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
      endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
      key: 'selection',
    },
  ],


  // success states
  isEventGetSuccess: false,
  isEventCreateSuccess: false,
  isEventDeleteSuccess: false,
  isEventUpdateSuccess: false,


  // loading states
  isEventCreateLoading: false,
  isEventsGetLoading: false,
  isEventGetLoading: false,
  isEventDeleteLoading: false,
  isEventUpdateLoading: false,


  // error states
  isEventGetError: false,
  isEventCreateError: false,
  isEventDeleteError: false,
  isEventUpdateError: false,
};



export const createEvent = createAsyncThunk('createEvent', async (data, thunkAPI) => {
  const createEventRes = await smartTryCatch(eventService.createEvent, data, thunkAPI);
  return createEventRes;
});

export const getEventsByAuthentication = createAsyncThunk(
  'getEventsByAuthentication',
  async (data, thunkAPI) => {
    const getEvents = await smartTryCatch(
      eventService.getEventsByAuthentication,
      data,
      thunkAPI,
    );
    return getEvents;
  },
);
export const getEventsByFilters = createAsyncThunk(
  'getEventsByFilters',
  async (data, thunkAPI) => {
    const getEvents = await smartTryCatch(
      eventService.getEventsByFilters,
      data,
      thunkAPI,
    );
    return getEvents;
  },
);
export const getTaskPlannerBoard = createAsyncThunk(
  'getTaskPlannerBoard',
  async (data, thunkAPI) => {
    const getEvents = await smartTryCatch(
      eventService.getTaskPlannerBoard,
      data,
      thunkAPI,
    );
    return getEvents;
  },
);
export const getMaintenacePlanner = createAsyncThunk(
  'getMaintenacePlanner',
  async (data, thunkAPI) => {
    const getEvents = await smartTryCatch(
      eventService.getMaintenacePlanner,
      data,
      thunkAPI,
    );
    return getEvents;
  },
);
export const getMaintenacePlannerBoard = createAsyncThunk(
  'getMaintenacePlannerBoard',
  async (data, thunkAPI) => {
    const getEvents = await smartTryCatch(
      eventService.getMaintenacePlannerBoard,
      data,
      thunkAPI,
    );
    return getEvents;
  },
);
export const getMeterEventsByAuthentication = createAsyncThunk(
  'getMeterEventsByAuthentication',
  async (data, thunkAPI) => {
    const getEvents = await smartTryCatch(
      eventService.getMeterEventsByAuthentication,
      data,
      thunkAPI,
    );
    return getEvents;
  },
);

export const getEventById = createAsyncThunk('getEventById', async (data, thunkAPI) => {
  const getEvent = await smartTryCatch(eventService.getEventById, data, thunkAPI);
  return getEvent;
});
export const getMeterEventById = createAsyncThunk('getMeterEventById', async (data, thunkAPI) => {
  const getEvent = await smartTryCatch(meterService.getMeterById, data, thunkAPI);
  return getEvent;
});



export const deleteEvent = createAsyncThunk('deleteEvent', async (data, thunkAPI) => {
  const deleteEventRes = await smartTryCatch(eventService.deleteEvent, data, thunkAPI);
  return deleteEventRes;
});

export const updateEvent = createAsyncThunk('updateEvent', async (data, thunkAPI) => {
  const updateEventRes = await smartTryCatch(eventService.updateEvent, data, thunkAPI);
  return updateEventRes;
});

export const patchMeterEvent = createAsyncThunk('patchMeterEvent', async (data, thunkAPI) => {
  const patchMeterEventRes = await smartTryCatch(meterService.patchMeter, data, thunkAPI);
  return patchMeterEventRes;
});


export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isEventCreateError = false;
      state.isEventCreateSuccess = false;
      state.isEventCreateLoading = false;
      state.isEventGetError = false;
      state.isEventGetSuccess = false;
      state.isEventGetLoading = false;
      state.isEventDeleteError = false;
      state.isEventDeleteSuccess = false;
      state.isEventDeleteLoading = false;
      state.isEventUpdateError = false;
      state.isEventUpdateSuccess = false;
      state.isEventUpdateLoading = false;
      state.isEventsGetLoading = false;
      // state.event = null;
    },
    openEventModalCreate: (state) => {
      state.eventModalCreate = true;
    },
    closeEventModalCreate: (state) => {
      state.eventModalCreate = false;
    },
    openEventModalUpdate: (state) => {
      state.eventModalUpdate = true;
    },
    closeEventModalUpdate: (state) => {
      state.eventModalUpdate = false;
    },
    openEventModalDelete: (state) => {
      state.eventModalDelete = true;
    },
    closeEventModalDelete: (state) => {
      state.eventModalDelete = false;
    },
    setEvent: (state, action) => {
      state.event = action.payload;
    },
    setEventId: (state, action) => {
      state.eventId = action.payload;
    },
    setTicketType: (state, action) => {
      state.ticketType = action.payload;
    },

    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setPriority: (state, action) => {
      state.priority = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setDueFinish: (state, action) => {
      state.due_finish = action.payload;
    },
    setAssignedTo: (state, action) => {
      state.assigned_to = action.payload;
    },
    setLocation: (state, action) => {
      state.location = Number(action.payload);
    },
    setAsset: (state, action) => {
      state.asset = Number(action.payload);
    },
    setTime: (state, action) => {
      state.time = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setStartedDate: (state, action) => {
      state.start_date = action.payload;
    },
    setCompletedDate: (state, action) => {
      state.completed_date = action.payload;
    },
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    setFixedDate: (state, action) => {
      state.fixedDate = action.payload;
    },
    setRequestor: (state, action) => {
      state.rquestor = action.payload;
    },
    setCalendarView: (state, action) => {
      state.calendarView = action.payload;
    },
    setSelectedDateOnCalendar: (state, action) => {
      state.selectedDateOnCalendar = action.payload;
    },
    setFilterFeatures: (state, action) => {
      state.filterFeatures = action.payload;
    },

    setCalendarFilterSite: (state, action) => {
      state.calendarSite = action.payload;
    },
    setCalendarFilterSublocation: (state, action) => {
      state.calendarSublocation = action.payload;
    },

    setCalendarStaff: (state, action) => {
      state.calendarStaff = action.payload;
    },
    setCalendarFilterAdvanceStatus: (state, action) => {
      state.calendarFilterAdvanceStatus = action.payload;
    },

    // Task Planner Filters 
    setServicesType: (state, action) => {
      state.servicesType = action.payload;
    },
    setTaskFilterStartDate: (state, action) => {
      state.taskFilterStartDate = action.payload;
    },
    setTaskFilterEndDate: (state, action) => {
      state.taskFilterEndDate = action.payload;
    },
    setTaskDateFilterState: (state, action) => {
      state.taskDateFilterState = action.payload;
    },
    setTasksBoardSite: (state, action) => {
      state.tasksBoardSite = action.payload;
    },
    setTasksBoardSublocation: (state, action) => {
      state.tasksBoardSublocation = action.payload;
    },
    setTasksBoardStaff: (state, action) => {
      state.tasksBoardStaff = action.payload;
    },
    setTaskPlannerBoardDataOrigin: (state, action) => {
      state.taskPlannerBoardDataOrigin = action.payload;
    },
    setTaskPlannerBoardData: (state, action) => {
      state.taskPlannerBoardData = action.payload;
    },

    // Chart maintenance Filters 
    setMaintenanceChartSite: (state, action) => {
      state.maintenanceChartSite = action.payload;
    },
    setMaintenanceChartSublocation: (state, action) => {
      state.maintenanceChartSublocation = action.payload;
    },
    setMaintenanceChartStaff: (state, action) => {
      state.maintenanceChartStaff = action.payload;
    },
    setMaintenanceChartStatus: (state, action) => {
      state.maintenanceChartStatus = action.payload;
    },
    setMaintenanceChartFrequency: (state, action) => {
      state.maintenanceChartFrequency = action.payload;
    },
    setMaintenancePlannerBoardData: (state, action) => {
      state.maintenancePlannerBoardData = action.payload;
    },


    // Board maintenance Filters 
    setMaintenanceBoardSite: (state, action) => {
      state.maintenanceBoardSite = action.payload;
    },
    setMaintenanceBoardSublocation: (state, action) => {
      state.maintenanceBoardSublocation = action.payload;
    },
    setMaintenanceBoardStaff: (state, action) => {
      state.maintenanceBoardStaff = action.payload;
    },
    setMaintenanceBoardStatus: (state, action) => {
      state.maintenanceBoardStatus = action.payload;
    },
    setMaintenanceBoardFrequency: (state, action) => {
      state.maintenanceBoardFrequency = action.payload;
    },

    setMaintenancePlannerBoardDataOrigin: (state, action) => {
      state.maintenancePlannerBoardDataOrigin = action.payload;
    },

    setMaintenanceFilterStartDate: (state, action) => {
      state.maintenanceFilterStartDate = action.payload;
    },
    setMaintenanceFilterEndDate: (state, action) => {
      state.maintenanceFilterEndDate = action.payload;
    },
    setMaintenanceDateFilterState: (state, action) => {
      state.maintenanceDateFilterState = action.payload;
    },


    resetEvent: (state) => {
      state.eventId = null
      state.ticketType = ""
      state.title = ""
      state.description = ""
      state.priority = ""
      state.category = ""
      state.due_finish = ""
      state.assigned_to = []
      state.asset = ""
      state.location = ""
      state.time = ""
      state.status = ""
      state.start_date = ""
      state.completed_date = ""
      state.fixedDate = ""
      state.rquestor= ""
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEvent.pending, (state) => {
        state.isEventCreateLoading = true;
        state.isEventCreateSuccess = false;
        state.isEventCreateError = false;
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.isEventCreateLoading = false;
        state.isEventCreateSuccess = true;
        state.isEventCreateError = false;
        state.events.push(action.payload);
      })
      .addCase(createEvent.rejected, (state) => {
        state.isEventCreateLoading = false;
        state.isEventCreateSuccess = false;
        state.isEventCreateError = true;
      })
      .addCase(getEventsByAuthentication.pending, (state) => {
        state.isEventsGetLoading = true;
        state.isEventGetSuccess = false;
        state.isEventGetError = false;
      })
      .addCase(getEventsByAuthentication.fulfilled, (state, action) => {
        state.isEventsGetLoading = false;
        state.isEventGetSuccess = true;
        state.isEventGetError = false;
        state.events = action.payload;        
      })
      .addCase(getEventsByAuthentication.rejected, (state) => {
        state.isEventsGetLoading = false;
        state.isEventGetSuccess = false;
        state.isEventGetError = true;
      })
      .addCase(getEventsByFilters.pending, (state) => {
        state.isEventsGetLoading = true;
        state.isEventGetSuccess = false;
        state.isEventGetError = false;
      })
      .addCase(getEventsByFilters.fulfilled, (state, action) => {
        state.isEventsGetLoading = false;
        state.isEventGetSuccess = true;
        state.isEventGetError = false;
        state.events = [...action.payload.workorder];        
      })
      .addCase(getEventsByFilters.rejected, (state) => {
        state.isEventsGetLoading = false;
        state.isEventGetSuccess = false;
        state.isEventGetError = true;
      })
      .addCase(getTaskPlannerBoard.pending, (state) => {
        state.isEventsGetLoading = true;
        state.isEventGetSuccess = false;
        state.isEventGetError = false;
      })
      .addCase(getTaskPlannerBoard.fulfilled, (state, action) => {
        state.isEventsGetLoading = false;
        state.isEventGetSuccess = true;
        state.isEventGetError = false;
        const resData = [...action.payload.workorder]
        state.taskPlannerBoardDataOrigin = resData
       
      })
      .addCase(getTaskPlannerBoard.rejected, (state) => {
        state.isEventsGetLoading = false;
        state.isEventGetSuccess = false;
        state.isEventGetError = true;
      })
      .addCase(getMaintenacePlanner.pending, (state) => {
        state.isEventsGetLoading = true;
        state.isEventGetSuccess = false;
        state.isEventGetError = false;
      })
      .addCase(getMaintenacePlanner.fulfilled, (state, action) => {
        state.isEventsGetLoading = false;
        state.isEventGetSuccess = true;
        state.isEventGetError = false;
        state.maintenancePlannerChartData = action.payload.pm;        
        state.maintenancePlannerChartCount = action.payload.pm_tickets_count;        
      })
      .addCase(getMaintenacePlanner.rejected, (state) => {
        state.isEventsGetLoading = false;
        state.isEventGetSuccess = false;
        state.isEventGetError = true;
      })
      .addCase(getMaintenacePlannerBoard.pending, (state) => {
        state.isEventsGetLoading = true;
        state.isEventGetSuccess = false;
        state.isEventGetError = false;
      })
      .addCase(getMaintenacePlannerBoard.fulfilled, (state, action) => {
        state.isEventsGetLoading = false;
        state.isEventGetSuccess = true;
        state.isEventGetError = false;
        state.maintenancePlannerBoardDataOrigin = action.payload.pm;        
      })
      .addCase(getMaintenacePlannerBoard.rejected, (state) => {
        state.isEventsGetLoading = false;
        state.isEventGetSuccess = false;
        state.isEventGetError = true;
      })
      .addCase(getMeterEventsByAuthentication.pending, (state) => {
        state.isEventsGetLoading = true;
        state.isEventGetSuccess = false;
        state.isEventGetError = false;
      })
      .addCase(getMeterEventsByAuthentication.fulfilled, (state, action) => {
        state.isEventsGetLoading = false;
        state.isEventGetSuccess = true;
        state.isEventGetError = false;
        state.events = [...state.events, ...action.payload];
      })
      .addCase(getMeterEventsByAuthentication.rejected, (state) => {
        state.isEventsGetLoading = false;
        state.isEventGetSuccess = false;
        state.isEventGetError = true;
      })
      .addCase(getEventById.pending, (state) => {
        state.isEventGetLoading = true;
        state.isEventGetSuccess = false;
        state.isEventGetError = false;
      })
      .addCase(getEventById.fulfilled, (state, action) => {
        state.isEventGetLoading = false;
        state.isEventGetSuccess = true;
        state.isEventGetError = false;
        state.event = action.payload;
      })
      .addCase(getEventById.rejected, (state) => {
        state.isEventGetLoading = false;
        state.isEventGetSuccess = false;
        state.isEventGetError = true;
      })
      .addCase(getMeterEventById.pending, (state) => {
        state.isEventGetLoading = true;
        state.isEventGetSuccess = false;
        state.isEventGetError = false;
      })
      .addCase(getMeterEventById.fulfilled, (state, action) => {
        state.isEventGetLoading = false;
        state.isEventGetSuccess = true;
        state.isEventGetError = false;
        state.event = action.payload;
      })
      .addCase(getMeterEventById.rejected, (state) => {
        state.isEventGetLoading = false;
        state.isEventGetSuccess = false;
        state.isEventGetError = true;
      })
      .addCase(deleteEvent.pending, (state) => {
        state.isEventDeleteLoading = true;
        state.isEventDeleteSuccess = false;
        state.isEventDeleteError = false;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.isEventDeleteLoading = false;
        state.isEventDeleteSuccess = true;
        state.isEventDeleteError = false;
        state.events = state.events.filter((event) => event.id !== action.payload);
      })
      .addCase(deleteEvent.rejected, (state) => {
        state.isEventDeleteLoading = false;
        state.isEventDeleteSuccess = false;
        state.isEventDeleteError = true;
      })
      .addCase(updateEvent.pending, (state) => {
        state.isEventUpdateLoading = true;
        state.isEventUpdateSuccess = false;
        state.isEventUpdateError = false;
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.isEventUpdateLoading = false;
        state.isEventUpdateSuccess = true;
        state.isEventUpdateError = false;
        state.events = state.events?.map((event) =>
          event.id === action.payload.id ? action.payload : event,
        );
      })
      .addCase(updateEvent.rejected, (state) => {
        state.isEventUpdateLoading = false;
        state.isEventUpdateSuccess = false;
        state.isEventUpdateError = true;
      })
      .addCase(patchMeterEvent.pending, (state) => {
        state.isEventUpdateLoading = true;
        state.isEventUpdateSuccess = false;
        state.isEventUpdateError = false;
      })
      .addCase(patchMeterEvent.fulfilled, (state, action) => {
        state.isEventUpdateLoading = false;
        state.isEventUpdateSuccess = true;
        state.isEventUpdateError = false;
        state.events = state.events?.map((event) =>
          event.id === action.payload.id && event?.ticket_type === "meter" ? {...action.payload, ticket_type:"meter"} : event,
        );
      })
      .addCase(patchMeterEvent.rejected, (state) => {
        state.isEventUpdateLoading = false;
        state.isEventUpdateSuccess = false;
        state.isEventUpdateError = true;
      })

  },
});

export const {
  reset,
  openEventModalCreate,
  closeEventModalCreate,
  setEventId,
  setTicketType,
  setTitle,
  setDescription,
  setPriority,
  setCategory,
  setDueFinish,
  setAssignedTo,
  setLocation,
  setAsset,
  setTime,
  setStatus,
  setEvent,
  setStartedDate,
  setCompletedDate,
  openEventModalUpdate,
  closeEventModalUpdate,
  isEventGetLoading,
  resetEvent,
  setEvents,
  setRequestor,
  setFixedDate,
  setCalendarView,
  setSelectedDateOnCalendar,
  setFilterFeatures,
  setCalendarFilterSite,
  setCalendarFilterSublocation,
  setCalendarStaff,
  setServicesType,
  setCalendarFilterAdvanceStatus,
  setTaskPlannerBoardDataOrigin,
  setTaskPlannerBoardData,
  setMaintenancePlannerBoardData,
  setTaskFilterStartDate,
  setTaskFilterEndDate,
  setTaskDateFilterState,
  setTasksBoardSite,
  setTasksBoardSublocation,
  setTasksBoardStaff,
  setMaintenanceChartSite,
setMaintenanceChartSublocation,
setMaintenanceChartStaff,
setMaintenanceChartStatus,
setMaintenanceChartFrequency,
  setMaintenanceBoardSite,
setMaintenanceBoardSublocation,
setMaintenanceBoardStaff,
setMaintenanceBoardStatus,
setMaintenanceBoardFrequency,
setMaintenancePlannerBoardDataOrigin,
setMaintenanceFilterStartDate,
setMaintenanceFilterEndDate,
setMaintenanceDateFilterState,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
